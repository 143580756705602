import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Location, WindowLocation } from '@reach/router';
import { graphql, Link } from 'gatsby';
import { Form, Input, Button, Typography, Icon, Card, Descriptions, Col, Row, Table } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import 'moment/locale/ru';
import { usePrevious } from '../lib/hooks/usePrevious';
import Page from '../components/Page/Page';
import IndexLayout from '../layouts';
import GQL, { ContentfulProduct } from '../graphql-types';
import css from './status.module.scss';
import { RootModel } from '../redux/store';
import { GetOrderStatusActionCreator, ClearOrderStatusActionCreator } from '../redux/order/order.actions';

interface StatusData {
  products: GQL.ContentfulProductConnection;
}

interface StatusPageProps extends FormComponentProps {
  data: StatusData;
}

interface FullProductData {
  node: ContentfulProduct;
}

interface FinalData {
  name: string;
  quantity: string;
  price: number;
  discount: string;
}

const StatusPageComponent = (props: StatusPageProps) => {
  const { t, i18n } = useTranslation();
  const { form, data } = props;
  const { products } = data;
  const { getFieldDecorator } = form;
  const { Title } = Typography;
  const { Item } = Descriptions;

  const dispatch = useDispatch();
  const { loading, order } = useSelector((state: RootModel) => ({
    loading: state.order.status.loading,
    order: state.order.checkStatus,
  }));

  const [location, updateLocation] = useState<WindowLocation | {}>();
  const [uid, setUid] = useState<string>('');
  const [finalData, setFinalData] = useState<FinalData[]>([]);
  const prevLocation = usePrevious(location);

  const handleParams = (l: WindowLocation) => {
    if (l.search) {
      const url = new URL(l.href);
      const invoice = url.searchParams.get('id');
      if (invoice) {
        form.setFieldsValue({ orderId: invoice });
        dispatch(GetOrderStatusActionCreator(invoice));
      }
    }
  };

  const getDiscount = (fullProduct: FullProductData): string => {
    const discount =
      order &&
      order.invoice &&
      order.invoice.discount &&
      order.invoice.discount.find(elem => {
        if (elem.id === fullProduct.node.contentful_id) return true;
        return false;
      });
    return discount ? `\u20ac ${discount.total} - ${discount.title}` : '';
  };

  React.useEffect(() => {
    (order?.invoice?.products || []).map(item => {
      const fullProduct = products.edges.find(it => it.node.contentful_id === item.id);
      return setFinalData((prev: any) => [
        ...prev,
        {
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          discount: fullProduct ? getDiscount(fullProduct) : '',
        },
      ]);
    });
  }, []);

  React.useEffect(() => {
    if (location && location !== prevLocation) {
      handleParams(location as WindowLocation);
    }
  }, [location]);

  const onSubmit = () => {
    form.validateFields((errors, values) => {
      if (!errors) {
        dispatch(ClearOrderStatusActionCreator());
        dispatch(GetOrderStatusActionCreator(values.orderId));
        setUid(values.orderId);
      }
    });
  };

  const columns = [
    {
      title: t('status:description.product.title'),
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <span>{name}</span>,
    },
    {
      title: t('status:description.product.quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity: string) => <span>{quantity}</span>,
    },
    {
      title: t('status:description.product.price'),
      dataIndex: 'price',
      key: 'price',
      render: (price: string) => <span>{price}</span>,
    },
    {
      title: t('status:description.product.discount'),
      dataIndex: 'discount',
      key: 'discount',
      render: (discount: string) => <span>{discount}</span>,
    },
  ];

  return (
    <Location>
      {({ location: urlLocation }) => {
        if (location !== urlLocation) {
          updateLocation(urlLocation);
        }
        return (
          <IndexLayout>
            <Page className={css.page}>
              <Title>{t('status:title')}</Title>
              <Form layout="inline" className={css.form}>
                <Form.Item label={t('status:input.label')}>
                  {getFieldDecorator('orderId', {
                    rules: [
                      {
                        required: true,
                        message: t('status:input.messages.isRequired'),
                      },
                    ],
                  })(<Input placeholder={t('status:input.placeholder')} className={css.input} />)}
                </Form.Item>
                <Button type="primary" disabled={loading} onClick={() => onSubmit()}>
                  {loading && <Icon type="loading" />}
                  {t('status:primary.label')}
                </Button>
              </Form>
              {order && order.invoice && (
                <Card className={css.card}>
                  <Row type="flex" className={css.card_header}>
                    <Col md={{ span: 12 }} xs={{ span: 12 }}>
                      {/* <Descriptions>
                        <Item label={`${t('status:order')} №`}>{order.transaction_id && order.transaction_id}</Item>
                      </Descriptions> */}
                      <span className={css.card_header_date}>
                        {moment(order.invoice?.created)
                          .locale(i18n.language)
                          .format('MMMM Do YYYY, H:MM A')}
                      </span>
                    </Col>
                    {order.transaction_id ? (
                      <div className={css.status}>
                        <Icon type="check-circle" theme="filled" style={{ color: '#83E45C', fontSize: '24px' }} />
                        <span className={css.status_scs_text}>{t('status:description.title.status.success')}</span>
                      </div>
                    ) : (
                      <>
                        <div className={css.status}>
                          <Icon type="exclamation-circle" theme="filled" style={{ color: '#E66144', fontSize: '24px' }} />
                          <span className={css.status_rjc_text}>{t('status:description.title.status.failV2')}</span>
                        </div>
                        <Link
                          className={css.redirect_btn}
                          to={
                            process.env.NODE_ENV === 'production'
                              ? `https://paytest.goslim.pro/invoices/eshop/pay/${uid}`
                              : `https://dev-shop.goslim.pro/status/?id=${uid}`
                          }
                        >
                          {t('status:description.title.goPayment')}
                        </Link>
                      </>
                    )}
                  </Row>
                  <Row type="flex" className={css.card_body}>
                    <Col span={11}>
                      <Descriptions
                        column={1}
                        layout="horizontal"
                        className={css.card_body_table}
                        title={t('status:description.title.customer')}
                      >
                        <Item label={t('status:description.name')}>
                          {order.invoice.first_name && order.invoice.last_name && `${order.invoice.first_name} ${order.invoice.last_name}`}
                        </Item>
                        <Item label={t('status:description.email')}>{order.invoice.email && order.invoice.email}</Item>
                        <Item label={t('status:description.address')}>
                          {order.invoice.address}, {order.invoice.city}, {order.invoice.country}, {order.invoice.zip}
                        </Item>
                        <Item label={t('status:description.shippingFee')}>
                          {order.invoice?.total_amount_currency === 'EUR' && <span>&euro; </span>}
                          {order.invoice.shipment_info.total_price && `${order.invoice.shipment_info.total_price}`}
                        </Item>
                        {order.invoice.track_number && <Item label={t('status:description.track')}>{order.invoice.track_number}</Item>}
                        <Item label={t('status:description.track')}>
                          {order.invoice.track_number ? (
                            <a href={order.invoice.link_tracking}>{order.invoice.track_number}</a>
                          ) : (
                            t('status:description.processing')
                          )}
                        </Item>
                        <Item label={t('status:description.shipping')}>
                          <span style={{ textTransform: 'uppercase' }}>{order.invoice.shipment_info.method}</span>
                        </Item>
                      </Descriptions>
                    </Col>
                    <Col style={{ marginLeft: '20px' }} span={12}>
                      <Descriptions column={1} layout="horizontal" title={t('status:description.title.products')} />
                      <Table rowKey={record => record.name} pagination={false} dataSource={finalData} columns={columns} />
                    </Col>
                  </Row>
                  <Row type="flex" className={order.transaction_id ? css.card_footer : css.card_footer_rjc}>
                    <Descriptions>
                      <Item label={t('status:description.title.totals')}>
                        {order.invoice?.total_amount_currency === 'EUR' && <span>&euro;</span>} {order.invoice?.total_amount}
                      </Item>
                    </Descriptions>
                  </Row>
                </Card>
              )}
              {order && !order.success && !order.invoice && (
                <Card className={css.card_warning}>
                  <Title level={4}>
                    <Icon type="warning" theme="filled" />
                    {t('status:warning')}
                  </Title>
                </Card>
              )}
            </Page>
          </IndexLayout>
        );
      }}
    </Location>
  );
};

const StatusPage = Form.create<StatusPageProps>({})(StatusPageComponent);
export default StatusPage;

export const pageQuery = graphql`
  query($lang: String) {
    products: allContentfulProduct(filter: { node_locale: { eq: $lang } }) {
      edges {
        node {
          ...ProductFragment
        }
      }
    }
  }
`;
